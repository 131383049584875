<template>
  <div class="row justify-content-center d-lg-none d-md-none">
    <div class="col-md-12 ">
      <div id="example-2">
        <button class="btn-burger" @click="show = !show">
          <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 35.575 38.631" style="">
            <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
              <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="5.5"></path>
              <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="5.5"></path>
            </g>
          </svg>
          {{ btnText() }}
        </button>
        <transition name="slide">
          <aside v-show="show">
            <div class="text-end">
              <button class="btn-burger" @click="show = !show">
                X {{ btnText() }}
              </button>
            </div>
            <div class="anotherSide">
              <ul class="nav flex-column">
                <li class="nav-item" @click="show = !show" v-show="program_overview_permission">
                  <router-link :class="$route.name == 'program_overview' ||
                    $route.name == 'dashboard_survey_reports_SA' ||
                    $route.name == 'dashboard_survey_reports_EE' ||
                    $route.name == 'dashboard_scheduled_requests'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'program_overview' }" aria-current="page">
                    <span data-feather="home">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path id="Path_113" data-name="Path 113"
                          d="M13,21V11h8V21ZM3,13V3h8V13Zm6-2V5H5v6ZM3,21V15h8v6Zm2-2H9V17H5Zm10,0h4V13H15ZM13,3h8V9H13Zm2,2V7h4V5Z"
                          transform="translate(-3 -3)" fill=" " />
                      </svg>
                    </span> Program Overview
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="self_assessment_permission">
                  <router-link :class="$route.name == 'self_assessment_activity'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'self_assessment_activity' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17.28" height="18" viewBox="0 0 17.28 18">
                        <path id="Path_118" data-name="Path 118" d="M0,0H17.28V17.28H0Z" transform="translate(0 0.72)"
                          fill="none" />
                        <path id="Path_119" data-name="Path 119"
                          d="M4,19a6.857,6.857,0,1,1,13.714,0Zm7.714-5.072v3.357h3.993A5.151,5.151,0,0,0,11.714,13.928ZM10,17.286V13.928a5.151,5.151,0,0,0-3.993,3.357Zm.857-6A5.143,5.143,0,1,1,16,6.143,5.141,5.141,0,0,1,10.857,11.286Zm0-1.714A3.429,3.429,0,1,0,7.429,6.143,3.428,3.428,0,0,0,10.857,9.571Z"
                          transform="translate(-2.217 -1)" />
                      </svg>
                    </span>
                    Self-Assessment
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="ergo_eval_activity_permission">
                  <router-link :class="$route.name == 'ergo_eval_activity'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'ergo_eval_activity' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18.707" height="18" viewBox="0 0 18.707 18">
                        <g data-name="Group 12670">
                          <path data-name="Path 120" d="M0 0h18v18H0z" transform="translate(.088)" style="fill:none" />
                          <path data-name="Path 121"
                            d="M5 3v16h16v2H3V3zm15.293 3.293 1.414 1.414L16 13.414l-3-3-4.293 4.293-1.414-1.414L13 7.586l3 3z"
                            transform="translate(-3 -3)" />
                        </g>
                      </svg>
                    </span>
                    Ergo Eval Activity
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="follow_up_feedback_permission">
                  <router-link :class="$route.name == 'follow_up_feedback'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'follow_up_feedback' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16.2" height="18" viewBox="0 0 16.2 18">
                        <g data-name="Group 12667">
                          <path data-name="Path 115"
                            d="M14.6 14.6a2.7 2.7 0 1 1-2.7 2.7 2.7 2.7 0 0 1 2.7-2.7zm-9-3.6A3.6 3.6 0 1 1 2 14.6 3.6 3.6 0 0 1 5.6 11zm9 5.4a.9.9 0 1 0 .9.9.9.9 0 0 0-.9-.9zm-9-3.6a1.8 1.8 0 1 0 1.8 1.8 1.8 1.8 0 0 0-1.8-1.8zM13.25 2A4.95 4.95 0 1 1 8.3 6.95 4.949 4.949 0 0 1 13.25 2zm0 1.8a3.15 3.15 0 1 0 3.15 3.15 3.15 3.15 0 0 0-3.15-3.15z"
                            transform="translate(-2 -2)" />
                        </g>
                      </svg>
                    </span>
                    Follow-Up Feedback
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="employee_search_permission">
                  <router-link :class="$route.name == 'employee_search' || $route.name == 'view_employee'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'employee_search' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 12668" width="18" height="18"
                        viewBox="0 0 18 18">
                        <path data-name="Path 116" d="M0 0h18v18H0z" style="fill:none" />
                        <path data-name="Path 117"
                          d="M10 10.75v1.5a4.5 4.5 0 0 0-4.5 4.5H4a6 6 0 0 1 6-6zm0-.75a4.5 4.5 0 1 1 4.5-4.5A4.5 4.5 0 0 1 10 10zm0-1.5a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm7.084 6.774 1.128 1.126-1.06 1.06-1.128-1.128a3 3 0 1 1 1.061-1.061zM14.5 15.25a1.5 1.5 0 1 0-1.5-1.5 1.5 1.5 0 0 0 1.5 1.5z"
                          transform="translate(-1 -.25)" />
                      </svg>
                    </span>
                    Employee Search
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="client_activity_permission">
                  <router-link :class="$route.name == 'client_activity'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'client_activity' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17.899" height="16.918" viewBox="0 0 17.899 16.918">
                        <path data-name="Icon open-graph"
                          d="M15.677 1 8.351 8.263 6.263 6.176 1 11.5l1.088 1.088 4.175-4.237 2.088 2.088L16.7 2.088zM0 14.614V16.7h16.7v-2.086z"
                          transform="translate(.5 -.284)" />
                      </svg>
                    </span>
                    Client Activity
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="evaluator_permission">
                  <router-link :class="$route.name == 'evaluator' ||
                    $route.name == 'evaluator_employee_search' ||
                    $route.name == 'evaluator_employee_view'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'evaluator' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 13108" width="23" height="23"
                        viewBox="0 0 23 23">
                        <path data-name="Path 9424" d="M0 0h23v23H0z" style="fill:none" />
                        <path data-name="Path 9425"
                          d="M13.737 3.8H4.789v14.4h12.527V7.4h-3.579zM3 2.893A.893.893 0 0 1 3.894 2h10.738l4.474 4.5v12.594a.9.9 0 0 1-.888.906H3.888A.9.9 0 0 1 3 19.107zM12.421 13.2a3.567 3.567 0 0 1-4.637-.9 3.618 3.618 0 0 1 .289-4.742 3.563 3.563 0 0 1 4.722-.3 3.614 3.614 0 0 1 .891 4.674l1.977 1.989-1.263 1.27-1.979-1.991zm-.553-1.829a1.807 1.807 0 0 0 0-2.545 1.782 1.782 0 0 0-2.531 0 1.807 1.807 0 0 0 0 2.545 1.782 1.782 0 0 0 2.531 0z" />
                      </svg>
                    </span>
                    Evaluator
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="client_manager_permission">
                  <router-link :class="$route.name == 'client_manager' ||
                      $route.name == 'client_program_overview'
                      ? 'router-link active'
                      : 'router-link'
                    " class="nav-link" :to="{ name: 'client_manager' }" aria-current="page">
                    <span data-feather="shopping-cart">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17.28" height="18" viewBox="0 0 17.28 18">
                        <path id="Path_118" data-name="Path 118" d="M0,0H17.28V17.28H0Z" transform="translate(0 0.72)"
                          fill="none" />
                        <path id="Path_119" data-name="Path 119"
                          d="M4,19a6.857,6.857,0,1,1,13.714,0Zm7.714-5.072v3.357h3.993A5.151,5.151,0,0,0,11.714,13.928ZM10,17.286V13.928a5.151,5.151,0,0,0-3.993,3.357Zm.857-6A5.143,5.143,0,1,1,16,6.143,5.141,5.141,0,0,1,10.857,11.286Zm0-1.714A3.429,3.429,0,1,0,7.429,6.143,3.428,3.428,0,0,0,10.857,9.571Z"
                          transform="translate(-2.217 -1)" />
                      </svg>
                    </span>
                    Program Management
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="reportpermission">
                  <router-link :class="$route.name == 'my_reports' ? 'router-link active' : 'router-link'"
                    class="nav-link" :to="{ name: 'my_reports' }" aria-current="page">
                    <span data-feather="home">
                      <svg xmlns="http://www.w3.org/2000/svg" data-name="report-svgrepo-com (1)" width="14.446"
                        height="18.168" viewBox="0 0 14.446 18.168">
                        <path data-name="Path 9441"
                          d="M29.45 0H19.7v18.168h14.445V4.695zm2.275 4.28h-1.86V2.42zM21.116 16.751V1.417h7.332v3.572a.709.709 0 0 0 .709.709h3.571v11.053z"
                          transform="translate(-19.699)" />
                        <path data-name="Rectangle 2342" transform="translate(3.16 14.078)" d="M0 0h8.126v.945H0z" />
                        <path data-name="Rectangle 2343" transform="translate(3.16 5.206)" d="M0 0h4.824v.945H0z" />
                        <path data-name="Path 9442"
                          d="M58.178 77.88h.522l-2.022 2.02-1.578-1.579-3.5 3.5.668.668 2.83-2.831 1.582 1.582 2.692-2.692v.522h.945v-2.135h-2.139z"
                          transform="translate(-48.583 -69.666)" />
                        <path data-name="Rectangle 2344" transform="translate(3.16 3.145)" d="M0 0h4.824v.945H0z" />
                      </svg>
                    </span> My Reports
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="helpcenterpermission">
                  <router-link :class="$route.name == 'all_user' || $route.name == 'help_center'
                    ? 'router-link active'
                    : 'router-link'
                    " class="nav-link" :to="{ name: 'help_center' }" aria-current="page">
                    <span data-feather="home" class="help-center stroke_icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" style="&#10;">
                        <path style="fill:none;stroke: none;" d="M0 0h18v18H0z" stroke="none" />
                        <g data-name="Group 13956" transform="translate(-1.875 -1.875)">
                          <circle cx="8" cy="8" r="8" transform="translate(2.875 2.875)"
                            style="stroke:#4b4b4b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none" />
                          <circle data-name="Oval" cx="4.694" cy="4.694" r="4.694" transform="translate(6.181 6.181)"
                            style="stroke:#4b4b4b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none" />
                          <path transform="translate(4.358 4.36)"
                            style="stroke:#4b4b4b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
                            d="M3.147 3.547 0 1" />
                          <path data-name="Path" transform="translate(12.745 4.36)"
                            style="stroke:#4b4b4b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
                            d="M3 4 4 3" />
                          <path data-name="Path" transform="translate(12.745 12.745)"
                            style="stroke:#4b4b4b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
                            d="m2 1 2 2" />
                          <path data-name="Path" transform="translate(4.358 12.745)"
                            style="stroke:#4b4b4b;stroke-linecap:round;stroke-linejoin:round;stroke-width: 1.5px;fill:none"
                            d="M2 1 0 2.247" />
                        </g>
                      </svg>
                    </span> Help Center
                  </router-link>
                </li>
                <li class="nav-item" @click="show = !show" v-show="custom_search_permission">
                  <router-link :class=" $route.name == 'custom_search_self_assessment' ? 'router-link active' : 'router-link' " class="nav-link" :to="{ name: 'custom_search_self_assessment' }" aria-current="page" >
                    <span data-feather="home" class="custom-s">
                     <svg xmlns="http://www.w3.org/2000/svg" width="18.012" height="18" viewBox="0 0 18.012 18">
                        <g id="custom_search" transform="translate(-9.999 -10.329)">
                          <g id="Group_14402" data-name="Group 14402" transform="translate(9.999 10.329)">
                            <path id="Path_9470" data-name="Path 9470" d="M16.235-500.61a7.309,7.309,0,0,0-5.732,4.557,7.526,7.526,0,0,0-.3,4.432,7.364,7.364,0,0,0,4.412,5.043,7.277,7.277,0,0,0,6.069-.321l.549-.286,2.155,2.143c2.508,2.5,2.347,2.371,3.056,2.371a1.2,1.2,0,0,0,.658-.106,1.818,1.818,0,0,0,.784-.772,1.131,1.131,0,0,0,.125-.69c0-.709.125-.549-2.382-3.064l-2.155-2.159.086-.121A10,10,0,0,0,24.221-491a7.383,7.383,0,0,0-.133-5.105,6.976,6.976,0,0,0-1.595-2.41,7.22,7.22,0,0,0-4.146-2.1A9.512,9.512,0,0,0,16.235-500.61Zm2.371,1.591a5.805,5.805,0,0,1,4.377,4.483,7.153,7.153,0,0,1,0,2.312,5.842,5.842,0,0,1-2.1,3.4,5.772,5.772,0,0,1-1.677.921,4.817,4.817,0,0,1-1.908.294,4.847,4.847,0,0,1-1.818-.266,5.864,5.864,0,0,1-3.6-3.511,4.875,4.875,0,0,1-.325-1.767,5.152,5.152,0,0,1,.169-1.716,5.823,5.823,0,0,1,3.969-4.059,4.685,4.685,0,0,1,1.748-.2A4.2,4.2,0,0,1,18.606-499.019Z" transform="translate(-9.999 500.671)" fill=""/>
                            <path id="Path_9471" data-name="Path 9471" d="M135.047-377.342c0,.008-.031.239-.067.521l-.067.509-.325.106a5.612,5.612,0,0,0-.639.262l-.314.161-.4-.313c-.219-.172-.407-.314-.419-.314s-.262.243-.556.537l-.537.541.294.372a5.1,5.1,0,0,1,.317.423.861.861,0,0,1-.118.314,5.115,5.115,0,0,0-.259.615c-.133.4-.071.364-.788.462l-.372.047v1.528l.5.067a4.485,4.485,0,0,1,.525.082c.012.012.071.176.137.364a4.449,4.449,0,0,0,.255.611.864.864,0,0,1,.118.313c-.012.027-.157.219-.317.423l-.3.372.545.545.545.545.411-.317.411-.321.4.2a5.6,5.6,0,0,0,.647.259l.239.063.047.458c.027.251.055.486.067.521.02.063.09.071.78.071h.756l.02-.129c.016-.067.043-.306.071-.521l.047-.4.282-.082a4.411,4.411,0,0,0,.643-.259l.357-.18.4.314a5.166,5.166,0,0,0,.419.317c.012,0,.263-.243.556-.541l.537-.537-.329-.411-.325-.411.11-.2a4.585,4.585,0,0,0,.372-.929c.031-.161.008-.153.678-.231l.4-.047v-1.548l-.38-.043c-.729-.09-.654-.055-.729-.349a4.451,4.451,0,0,0-.255-.639l-.188-.376.321-.407.325-.407-.537-.541c-.294-.294-.545-.537-.556-.537s-.2.141-.419.314l-.4.314-.388-.188a4.541,4.541,0,0,0-.643-.262l-.255-.067-.047-.419c-.027-.227-.055-.462-.067-.525l-.024-.106h-.76C135.392-377.35,135.051-377.346,135.047-377.342Zm1.5,2.425a2.708,2.708,0,0,1,1.955,2.586,2.8,2.8,0,0,1-1.011,2.092,2.644,2.644,0,0,1-1.693.576,2.638,2.638,0,0,1-2.081-1,2.694,2.694,0,0,1,.278-3.644A2.669,2.669,0,0,1,136.544-374.917Z" transform="translate(-128.58 379.617)" fill=""/>
                            <path id="Path_9472" data-name="Path 9472" d="M336.222-172.255a1.462,1.462,0,0,0-.674.643,1,1,0,0,0-.1.549.908.908,0,0,0,.106.549,1.546,1.546,0,0,0,.65.619,1.36,1.36,0,0,0,1.031-.024,1.448,1.448,0,0,0,.635-.647,1.38,1.38,0,0,0-.016-1.031,1.33,1.33,0,0,0-.6-.623A1.482,1.482,0,0,0,336.222-172.255Z" transform="translate(-329.468 178.365)" fill=""/>
                          </g>
                        </g>
                      </svg>
                    </span>
                    Custom Search
                  </router-link>
                </li>
              </ul>
            </div>
          </aside>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileSidebar",
  data() {
    return {
      show: false,
      from: null,
      helpcenterpermission: false,
      reportpermission: false,
      program_overview_permission: false,
      self_assessment_permission: false,
      ergo_eval_activity_permission: false,
      follow_up_feedback_permission: false,
      employee_search_permission: false,
      evaluator_permission: false,
      client_activity_permission: false,
      client_manager_permission: false,
      custom_search_permission: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  beforeMount() {
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      // Store for check permission
      // let res = this.$store.dispatch("auth/getAuthUser");
      let value = this.$store.getters["auth/authUser"];
      if (value) {
        let userPermissions = value.user.roles.all_permission;
        let help_center = userPermissions.includes(
          "dashboard-pages-employee-help-center-view"
        );
        if (help_center == true) {
          this.helpcenterpermission = true;
        }
        let report_permission = userPermissions.includes(
          "dashboard-pages-employee-dashboard-view"
        );
        if (report_permission == true) {
          this.reportpermission = true;
        }
        let program_overview = userPermissions.includes(
          "dashboard-pages-program-overview-view"
        );
        if (program_overview == true) {
          this.program_overview_permission = true;
        }
        let self_assessment = userPermissions.includes(
          "dashboard-pages-self-assessment-view"
        );
        if (self_assessment == true) {
          this.self_assessment_permission = true;
        }
        let ergo_eval_activity = userPermissions.includes(
          "dashboard-pages-ergo-eval-activity-view"
        );
        if (ergo_eval_activity == true) {
          this.ergo_eval_activity_permission = true;
        }
        let follow_up_feedback = userPermissions.includes(
          "dashboard-pages-follow-up-feedback-view"
        );
        if (follow_up_feedback == true) {
          this.follow_up_feedback_permission = true;
        }
        let employee_search = userPermissions.includes(
          "dashboard-pages-employee-search-view"
        );
        if (employee_search == true) {
          this.employee_search_permission = true;
        }
        let evaluatorSubPermissions = [
          "evaluator-client-list",
          "evaluator-reports-in-review",
        ];
        let evaluator = userPermissions.some((item) =>
          evaluatorSubPermissions.includes(item)
        );

        // let evaluator = userPermissions.includes('dashboard-pages-evaluator');
        if (evaluator == true) {
          this.evaluator_permission = true;
        }
        let client_activity = userPermissions.includes(
          "dashboard-pages-client-activity-view"
        );

        if (client_activity == true) {
          this.client_activity_permission = true;
        }
        let client_manager = userPermissions.includes(
          "dashboard-pages-client-managers-view"
        );
        if (client_manager == true) {
          this.client_manager_permission = true;
        }
        let custom_search = userPermissions.includes(
          "custom-search"
        );
        if (custom_search == true) {
          this.custom_search_permission = true;
        }
      }
    }
  },
  methods: {
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
  },
};
</script>