import Vue from "vue";
import Vuex from "vuex";

import * as Home from "./modules/Home"
import * as auth from "@/store/modules/Auth";
import * as ClientsConfig from "@/store/modules/ClientsConfig";
Vue.use(Vuex);

export default new Vuex.Store({
    strict:true,

    modules:{
        auth,
        Home,
        ClientsConfig,
    },
})