<template>
    <div class="loading-page" v-show="is_full_page_loader"> <!--  -->
      <img src="../assets/images/loader.gif" alt="loading" />
    </div>
</template>

<script>
   export default{
        name: 'fullpageloader',
        data() {
            return {
                is_full_page_loader:true
            }
        }
   }
</script>