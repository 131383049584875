<template>
    <transition name="fade">
      <div class="sso-modal">
      <div class="custom-modal-backdrop box_up user_box_up">
        <div class="custom-modal pop_custom region_popup" role="dialog">
          <header class="modal-header for_pos" id="modalTitle">
            <slot name="header">Please select region</slot> 
                
          </header>
          <section class="modal-body add_scroller cust_scroll" id="modalDescription">
            <slot name="body">
              <form
                data-vv-scope="updateRegionForm"
              >
                <div class="form-body marg_none">
                  <div class="grid regionin">
                      <v-select
                        :reduce="(allRegions) => allRegions.id"
                        :options="allRegions"
                        label="name"
                        placeholder="Region"
                        name="Region"
                        v-model="regionData"
                        v-validate="'required'"
                      />
                  </div>
                  <small
                   class="error text-danger"
                   v-show="errors.has('updateRegionForm.Region')"
                  >
                   Region is required
                 </small> 
                </div>                
              </form>
            </slot>
          </section>
          <div class="btnsgroup">
            <button type="button" @click="updateRegion" class="btn btn_primary pop_btn">Add</button>
            <!-- <button type="button" class="btn btn_outline ml_24 pop_btn blind_bg" @click="$emit('closeModal'),$validator.reset();">
            Cancel
            </button> -->
          </div>
        </div>
      </div>
    </div>
    </transition>
  </template>
  <script>
  import commonFunction from '../mixin/commonFunction';
  import "vue-select/dist/vue-select.css";
  export default {
    name: "clientspopup",
    mixins: [commonFunction],
    data() {
        return {
            client_id: "",
            regionData: "",
            base_url: null,
        };
    },
    props: {
      allRegions: {
        type: [Array, Object],
      },
    },
    mounted() {
      this.base_url = process.env.VUE_APP_API_URL;
    },
    methods: {
      updateRegion() {
        var _this = this;
        var requestData = { region:_this.regionData};
        _this.$validator.validateAll("updateRegionForm").then((isValid) => {
            if (isValid) {
            let config = {
              method: "post",
              url: process.env.VUE_APP_API_URL + "/api/update-user-region/",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: {request_data : this.encodeAPIRequest(requestData)},
            };
            _this
              .axios(config)
              .then(({ data }) => {
                var body = document.body;
                body.classList.remove("hide_scroll");
                if (data.success) {this.$emit("closeModal", false);
                  _this.$toast.success(data.message, {
                      position: "top-right",
                      duration: 2000,
                    });
                }
              })
              .catch((response) => {
                this.$emit("closeModal", false);
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
                if (response.status == 401) {
                  this.$router.push({ name: "login" });
                }
            });
            }
        });
      },
    },
  };
  </script>
  <style>
  
  .sso-modal{
    transition: none !important;
  }
  .sso-modal.fade-leave-active{
    transition: none !important;
  }
  .sso-modal.fade-leave-active.fade-leave-to{
    transition: none !important;
  }
  .sso-modal .custom-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #141414; */
    background: rgba(0,0,0,.8196078431);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: none;
  }
  
  .sso-modal .custom-modal {
    background: #fff;
    width: 70%;
    height: 50%;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    left: 15%;
    top: 20%;
    transition: none;
  }
  
  .sso-modal .modal-header {
    padding: 15px;
    display: flex;
  }
  
  .sso-modal .modal-header {
    border-bottom: 1px solid #fff;
    font-size: 30px;
    color: #000;
    justify-content: center;
  }
  .sso-modal .modal-body {
    position: relative;
    font-size: 30px;
    align-self: center;
    padding: 20px 10px;
  }
  .sso-modal .close {
    border: none;
    font-size: 30px;
    margin-left: 100px;
    cursor: pointer;
    font-weight: bold;
    color: #000;
    background: transparent;
  }
  .sso-modal .form-body {
    margin: 5%;
  }
  .admin_dash_cls .sso-modal .pop_custom .add_scroller .no_client_pop{
          color: #f19836 !important;
          border: 2px solid #f19836;
            font-size:26px;
            min-height: calc(50vh - 325px);
            margin: 100px 0px;
          
  }
    @media screen and (max-width:991px) {
      .admin_dash_cls .sso-modal .pop_custom .add_scroller .no_client_pop{
              font-size: 20px;
              min-height: calc(52vh - 325px);       
              margin: 71px 0px;
            }
          }
   .admin_dash_cls .sso-modal .pop_custom .btnsgroup .blind_bg:hover {
      background-color: #f19836 !important;
      color: #fff !important;
      box-shadow: 0px 10px 17px -10px var(--color_orange);
  }        
  </style>
  