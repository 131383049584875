import axios from "axios";
import store from "@/store";

export const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  withCredentials: true, // required to handle the CSRF token
});
// console.log(process.env.VUE_APP_API_URL)

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    if (response.data.token) {
      window.localStorage.setItem("userToken", response.data.token);
    }
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/authUser"] &&
      !store.getters["auth/guest"]
    ) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default {
  async login(payload) {
    return authClient.post("/login", payload);
  },
  async registerUser(payload) {
    return authClient.post("/register", payload);
  },
  logout() {
    return authClient.get("/logout", {
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
      }
    });
  },
  async forgotPassword(payload) {
    return authClient.post("/sendpasswordlink", payload);
  },
  async resetPassword(payload) {
    return authClient.post("/reset-password", payload);
  },
  getAuthUser() {
    if(window.localStorage.getItem("userToken")){
      return  authClient.get("/get-user", {
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
        }
      }).then((response) => {
        // console.log(response);
        var encrypted = response.data.data;
        var key = process.env.VUE_APP_ENC_KEY;
        //var key = window.localStorage.getItem("userToken");
        //var key = "uOKSCZ4mPGa4EwD9wsQnK40N5ep34zPdn+UmRvnowrE=";
        var break_data = encrypted.replace(key,'');
        var slice_random = break_data.slice(20);
        // console.log(break_data);      
        var decrypted_json = JSON.parse(window.atob(slice_random));
        response.data.user = decrypted_json.user;
        response.data.token = decrypted_json.token;
        response.data.client = decrypted_json.client;
        response.data.seats = decrypted_json.seats;
        return response;
      });
      
    }
  },

  getAllClients() {
    return authClient.get("/clients", {
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
      }
    });
  },

  ssoLogin(payload) {
    return authClient.post("/client/sso-login", payload, {
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
      }
    });
  },

  getUserReportByEmail() {
    return axios.get(process.env.VUE_APP_API_URL +"/api/employee-report", {
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
      }
    });
  },

  downloadZipFile(url) {
    return axios.get(url, {
      responseType: 'blob',
    });
  },

  userDomainCheck() {
    if(window.localStorage.getItem("userToken")){
      return authClient.get("/check-domain", {
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
        }
      });
    }
  },

  removeUser() {
    if(window.localStorage.getItem("userToken")){
      return authClient.get("/remove-user", {
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem("userToken")}`,
        }
      });
    }
  },

};