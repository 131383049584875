<template>
  <div
    id="main-div"
    :class="
      $route.meta.is_header == 'home' && company_code != 'airbnb'
        ? 'public-cls'
        : $route.meta.is_header == 'home' && company_code == 'airbnb'
        ? 'public-cls customer-a45'
        : $route.meta.is_header == 'admin' && company_code != 'airbnb'
        ? 'admin_dash_cls'
        : $route.meta.is_header == 'admin'
        ? 'admin_dash_cls airbnb'
        : $route.meta.is_header == 'airbnb'
        ? 'customer-a45'
        : company_code == 'airbnb' && $route.meta.is_header != 'login'
        ? 'user-site-cls airbnb'
        : 'user-site-cls'
    "
  >
    <fullpageloader ref="fullpageloader"></fullpageloader>

    
    <Header
      v-if="
        ($route.meta.is_header == 'user_dashboard' ||
          $route.meta.is_header == 'admin') &&
        company_code != 'airbnb'
      "
      ref="headercompnent"
    />
    <HeaderHome
      v-if="$route.meta.is_header == 'home'"
      ref="headercompnent"
    />
    <LoginHeader v-if="$route.meta.is_header == 'login'" />
    <HeaderAirbnb
      v-if="($route.meta.is_header == 'airbnb' || company_code == 'airbnb') && $route.meta.is_header != 'home'"
      ref="airbnbheadercompnent"
    />

    <div
      class="dashboard_section empdashboard-cls"
      v-if="
        ($route.meta.is_sidebar == 'employee-sidebar' ||
          $route.meta.is_sidebar == 'clientmanager-sidebar') &&
        $route.meta.is_header != 'admin' &&
        $route.meta.is_header != 'home' &&
        $route.meta.is_header == 'user_dashboard'
      "
    >
      <div class="container-fluid">
        <div class="row">
          <EmployeeSideBar
            v-if="$route.meta.is_sidebar == 'employee-sidebar'"
          />
          <MobileSidebar v-if="$route.meta.is_sidebar == 'employee-sidebar'"/>
          <ClientManagerSideBar
            v-if="$route.meta.is_sidebar == 'clientmanager-sidebar'"
          />
          <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <main>
              <router-view :key="$route.path"></router-view>
            </main>
          </div>
        </div>
      </div>
    </div>
    <div
      class="dashboard_section"
      v-else-if="
        $route.meta.is_header == 'admin' &&
        $route.meta.is_header != 'home' &&
        $route.meta.is_header != 'user_dashboard'
      "
    >
      <div class="container-fluid">
        <div class="row">
          <SideBar />
          <router-view :key="$route.path"></router-view>
        </div>
      </div>
    </div>
    <main v-else>
      <router-view :key="$route.path"></router-view>
    </main>

    <Footer
      v-if="
        ($route.meta.is_header && company_code != 'airbnb' && $route.meta.is_header != 'logout') ||
        $route.meta.is_header == 'login'
      "
    />
    <FooterAirbnb
      v-if="
        ($route.meta.is_header == 'airbnb' || company_code == 'airbnb') &&
        $route.meta.is_header != 'login' && $route.meta.is_header != 'logout'
      "
    />
  </div>
</template>


<script>
import Header from "./components/Header.vue";
import HeaderHome from "./components/HeaderHome.vue";
import LoginHeader from "./components/LoginHeader.vue";
import EmployeeSideBar from "./components/EmployeeSideBar.vue";
import ClientManagerSideBar from "./components/ClientManagerSideBar.vue";
import SideBar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
import HeaderAirbnb from "./components/HeaderAirbnb.vue";
import FooterAirbnb from "./components/FooterAirbnb.vue";
import MobileSidebar from "./components/MobileSidebar.vue";
export default {
  name: "App",
  components: {
    LoginHeader,
    Header,
    EmployeeSideBar,
    ClientManagerSideBar,
    Footer,
    HeaderHome,
    SideBar,
    HeaderAirbnb,
    FooterAirbnb,
    MobileSidebar
  },
  data() {
    return {
      from: null,
      // company_code: null,
      main_div_class: "public-cls",
    };
  },
  computed: {
    company_code() {
      let data = this.$store.getters["auth/authUser"];
      if(data != null ) {
        let company_data = data.client;
        if (company_data == null) {
          company_data = [];
        }
        if (company_data.length == 0 || company_data.length === "undefined") {
          return null;
        } else {
          return company_data.company_code.toLowerCase();
        }
      } else {
        return null;
      }
    },
    color_client() {
      let data = this.$store.getters["auth/authUser"];
      if(data == null)
        return null;

      return data.client;
      
    }
  },
  mounted() {
    document.title = "PBErgo | Ergonomics Training | Consulting | Consultant";
    setTimeout(() => {
      this.$refs.fullpageloader.is_full_page_loader = false;

      /* Code taken from main.js */
      /*var color_orange = document.querySelector(':root');
      var primary_color = document.querySelector(':root');
      var secondary_color = document.querySelector(':root');
      // var color_client = JSON.parse(localStorage.getItem("companyInformation"));
      
      if(this.color_client == null || this.color_client.length == 0 ||this.color_client.length === 'undefined'){
        color_orange.style.setProperty('--color_orange', '#FF9500');
        primary_color.style.setProperty('--primary_color', '#FF9500');
        secondary_color.style.setProperty('--secondary_color', '#1A191C');  
      }else{
        color_orange.style.setProperty('--color_orange', this.color_client.hex_color);
        primary_color.style.setProperty('--primary_color', this.color_client.hex_color);
        secondary_color.style.setProperty('--secondary_color', this.color_client.secondary_hex_color);
      }*/
      /* Ends here */
    }, 50);
    /****  Commented because now we are using getter
    let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    if (company_data == null) {
      company_data = [];
    }
    if (company_data.length == 0 || company_data.length === "undefined") {
      console.log("no company values header");
    } else {
      this.company_code = company_data.company_code.toLowerCase();
    }
    ***/
  },
};
</script>
