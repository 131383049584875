export default function guest({ next, store }) {
    const storageItem = window.localStorage.getItem("guest");
    if (storageItem === "isNotGuest" && !store.getters["auth/authUser"]) {
      store.dispatch("auth/getAuthUser").then(() => {
        if (store.getters["auth/authUser"]) {
           next({ name: "dashboard" });
        } else {
          window.localStorage.removeItem('userToken');
          store.dispatch("auth/setGuest", { value: "isGuest" });
          next();
        }
      });
    } else {
      next();
    }
  }