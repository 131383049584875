import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store/index";
import moment from 'moment';
import axios from "axios";
import VueAxios from "vue-axios";
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm.min';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import "@/assets/css/admin.css"
import "@/assets/css/style.min.css"
import "bootstrap/dist/js/bootstrap";
import VScrollLock from "v-scroll-lock";
import vueScrollto from "vue-scrollto";
//home side css
import "@/assets/home/css/style.min.css";
//admin end css
import "@/assets/admin/css/style.min.css";

//airbnb css
import "@/assets/css/airbnb.min.css";
//airbnb end css

// import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.min.js"
import "datatables.net-buttons/js/buttons.colVis.min.js"
import "datatables.net-buttons/js/buttons.flash.min.js"
import "datatables.net-buttons/js/buttons.html5.min.js"
import "datatables.net-buttons/js/buttons.print.min.js"
import VueElementLoading from "vue-element-loading";
import veevalidate from 'vee-validate';
import VueMatomo from 'vue-matomo';


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
Vue.use(Tooltip);



const config = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'en',
  validity: false,
  useConstraintAttrs: true
};

Vue.use(veevalidate, config);
const maxDimensionsRule = {
  getMessage(field, [width, height], data) {
      return (data && data.message) || `The ${field} must be upto ${width} X ${height} pixels.`;
  },
  validate(files, [width, height]) {
    const validateImage = (file, width, height) => {
      if(file.type == "image/svg+xml"){
        return new Promise(resolve => {
          let svgReader = new FileReader();
          var doc, viewBox;
          svgReader.onerror = () => resolve({ valid: false });
          svgReader.onload = function () {
            doc = new DOMParser().parseFromString(svgReader.result, 'application/xml'),
            viewBox = doc.documentElement.viewBox.baseVal;
          }
          svgReader.onloadend = () => resolve({
            // alert('height:'+viewBox.height+' width:'+viewBox.width);
            valid: viewBox.width <= Number(width) && viewBox.height <= Number(height)
          });
          svgReader.readAsText(file);
        });
      } else {
        const URL = window.URL || window.webkitURL;
        return new Promise(resolve => {
          const image = new Image();
          image.onerror = () => resolve({ valid: false });
          image.onload = () => resolve({
            valid: image.width <= Number(width) && image.height <= Number(height) // only change from official rule
          });

          image.src = URL.createObjectURL(file);
        });
      }
    };
    const list = [];
    for (let i = 0; i < files.length; i++) {
      // if file is not an image, reject.
      if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
        return false;
      }
      list.push(files[i]);
    }
    return Promise.all(list.map(file => validateImage(file, width, height)));
  }
};

const validEmailAttachment =  {
  getMessage() {
    return 'Please upload the file with a valid extension: doc, pdf, png, jpg, svg.';
  },
  validate(file, args) {
    var filetype = file[0].name.split(".").pop();
    if (args.includes(filetype)) {
      return true;
    } else {
      return false;
    }
  }
};


const multipleEmail = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /^(([a-zA-Z0-9_\-\s.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\s-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/.test(value); 
}

const validDomainName = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /^([a-z0-9])(([a-z0-9-]{1,61})?[a-z0-9]{1})?(\.[a-z0-9](([a-z0-9-]{1,61})?[a-z0-9]{1})?)?(\.[a-zA-Z]{2,4})+$/.test(value); 
  // return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value); 
}
const validVimeoUrl = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/.test(value); 
}

const dict = {
  custom: {
    // User Role Form
    user_role: {
      required: 'Role Name field is required.'
    },
    Description:{
      required: 'Description field is required.'
    },
    // User form
    first_name:{
      required: 'First Name is required.',
      max: 'First Name may not be greater than 20 characters',
    },
    'Last Name' :{
      required: 'Last Name is required.',
      max: 'Last Name may not be greater than 20 characters',
    },
    'User Role' :{
      required: 'User Role is required.',
    },
    email :{
      required: 'Email is required.',
      email: 'Email must be valid.',
    },
    Password :{
      required: 'Password is required.',
      min: 'Use 15 or more characters.',
    },
    Client :{
      required: 'Client is required.', // Used in email template too
    },
    Region :{
      required: 'Region is required.',
    },
    //Client Form
    company_name :{
      required : 'Company Name is required.',
      max: 'Company Name may not be greater than 30 characters '
    },
    company_code :{
      required : 'Company Code is required.',
      max: 'Company Code may not be greater than 10 characters '
    },
    client_type :{
      required : 'Client type is required.', // USed in email template, send notification forms too
    },
    status :{
      required : 'Status is required.',
    },
    banner_image :{
      required : 'Banner is required.',
    },
    logo_file :{
      required : 'Logo is required.',
    },
    default_user_role :{
      required : 'By Default User Role is required.',
    },
    report_type :{
      required : 'My report type is required.',
    },
    home_menu_text :{
      required : 'The Custom menu text for Home field is required.',
      max : 'The Custom menu text for Home field may not be greater than 20 characters.',
    },
    ergo_menu_text :{
      required : 'The Custom menu text for Ergo Essentials field is required.',
      max : 'The Custom menu text for  Ergo Essentials field may not be greater than 20 characters.',
    },
    guideline_menu_text :{
      required : 'The Custom menu text for  Setup Guidelines field is required.',
      max : 'The Custom menu text for  Setup Guidelines field may not be greater than 20 characters.',
    },
    work_better_menu_text :{
      required : 'The Custom menu text for Work Better field is required.',
      max : 'The Custom menu text for Work Better field may not be greater than 20 characters.',
    },
    videos_menu_text :{
      required : 'The Custom menu text for Videos field is required.',
      max : 'The Custom menu text for Videos field may not be greater than 20 characters.',
    },
    work_area :{
      required : 'Work form area is required.',
    },
    Name : {
      required: 'Name is required',
    },
    Subject : {
      required: 'Subject is required', // used in send  notification too
    },
    Content : {
      required: 'Content is required',
    },
    manage_program : {
      required: 'This field is required',
    },
    billablecost: {
      min_value: 'The Billable Cost must be a valid number',
      decimal: 'The Billable Cost must be a valid number'
    },
    password_confirmation : {
      required: 'Confirm password is required.',
    },
    import_file : {
      required: 'The file is required.',
      ext: 'Only CSV file types are allowed.',
    }
  }
};

Vue.component("VueElementLoading", VueElementLoading);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY').toUpperCase()
  }

});

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('formatDateAmerican', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY').toUpperCase()
  }

});

Vue.use(VScrollLock);
Vue.use(vueScrollto);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.config.debug = false;
Vue.config.silent = true; 

import FullPageLoader from './components/FullPageLoader.vue';
Vue.component('fullpageloader', FullPageLoader);


Vue.use(VueMatomo, {
  host: process.env.VUE_APP_MATOMO_URL,
  siteId: 1,
  trackerFileName: 'matomo',
  // router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
}); 

new Vue({
  BootstrapVue,
  router,
  store,
  render: h => h(App),
  created() {
    this.$validator.extend('maxdimensions', maxDimensionsRule);
    this.$validator.extend('multipleEmail', multipleEmail);
    this.$validator.extend('validDomainName', validDomainName);
    this.$validator.extend('validVimeoUrl', validVimeoUrl);
    this.$validator.extend('validEmailAttachment', validEmailAttachment);
    this.$validator.localize('en', dict);
  },
}).$mount('#app')


var color_orange = document.querySelector(':root');
var primary_color = document.querySelector(':root');
var secondary_color = document.querySelector(':root');
var color_client = JSON.parse(localStorage.getItem("companyInformation"));

if(color_client == null){
  color_client = [];
}
// console.log('debugging company data',typeof color_client,color_client.length);
if(color_client.length == 0 ||color_client.length === 'undefined'){  
  // console.log('in if');
  color_orange.style.setProperty('--color_orange', '#FF9500');
  primary_color.style.setProperty('--primary_color', '#FF9500');
  secondary_color.style.setProperty('--secondary_color', '#1A191C');  
}else{ 
  // console.log(localStorage.getItem("companyInformation"),'color_client');  
  color_orange.style.setProperty('--color_orange', color_client.hex_color);
  primary_color.style.setProperty('--primary_color', color_client.hex_color);
  secondary_color.style.setProperty('--secondary_color', color_client.secondary_hex_color);
}




