var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$route.meta.is_header == 'home' && _vm.company_code != 'airbnb'
      ? 'public-cls'
      : _vm.$route.meta.is_header == 'home' && _vm.company_code == 'airbnb'
      ? 'public-cls customer-a45'
      : _vm.$route.meta.is_header == 'admin' && _vm.company_code != 'airbnb'
      ? 'admin_dash_cls'
      : _vm.$route.meta.is_header == 'admin'
      ? 'admin_dash_cls airbnb'
      : _vm.$route.meta.is_header == 'airbnb'
      ? 'customer-a45'
      : _vm.company_code == 'airbnb' && _vm.$route.meta.is_header != 'login'
      ? 'user-site-cls airbnb'
      : 'user-site-cls',attrs:{"id":"main-div"}},[_c('fullpageloader',{ref:"fullpageloader"}),(
      (_vm.$route.meta.is_header == 'user_dashboard' ||
        _vm.$route.meta.is_header == 'admin') &&
      _vm.company_code != 'airbnb'
    )?_c('Header',{ref:"headercompnent"}):_vm._e(),(_vm.$route.meta.is_header == 'home')?_c('HeaderHome',{ref:"headercompnent"}):_vm._e(),(_vm.$route.meta.is_header == 'login')?_c('LoginHeader'):_vm._e(),((_vm.$route.meta.is_header == 'airbnb' || _vm.company_code == 'airbnb') && _vm.$route.meta.is_header != 'home')?_c('HeaderAirbnb',{ref:"airbnbheadercompnent"}):_vm._e(),(
      (_vm.$route.meta.is_sidebar == 'employee-sidebar' ||
        _vm.$route.meta.is_sidebar == 'clientmanager-sidebar') &&
      _vm.$route.meta.is_header != 'admin' &&
      _vm.$route.meta.is_header != 'home' &&
      _vm.$route.meta.is_header == 'user_dashboard'
    )?_c('div',{staticClass:"dashboard_section empdashboard-cls"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[(_vm.$route.meta.is_sidebar == 'employee-sidebar')?_c('EmployeeSideBar'):_vm._e(),(_vm.$route.meta.is_sidebar == 'employee-sidebar')?_c('MobileSidebar'):_vm._e(),(_vm.$route.meta.is_sidebar == 'clientmanager-sidebar')?_c('ClientManagerSideBar'):_vm._e(),_c('div',{staticClass:"col-md-9 ms-sm-auto col-lg-10 px-md-4"},[_c('main',[_c('router-view',{key:_vm.$route.path})],1)])],1)])]):(
      _vm.$route.meta.is_header == 'admin' &&
      _vm.$route.meta.is_header != 'home' &&
      _vm.$route.meta.is_header != 'user_dashboard'
    )?_c('div',{staticClass:"dashboard_section"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('SideBar'),_c('router-view',{key:_vm.$route.path})],1)])]):_c('main',[_c('router-view',{key:_vm.$route.path})],1),(
      (_vm.$route.meta.is_header && _vm.company_code != 'airbnb' && _vm.$route.meta.is_header != 'logout') ||
      _vm.$route.meta.is_header == 'login'
    )?_c('Footer'):_vm._e(),(
      (_vm.$route.meta.is_header == 'airbnb' || _vm.company_code == 'airbnb') &&
      _vm.$route.meta.is_header != 'login' && _vm.$route.meta.is_header != 'logout'
    )?_c('FooterAirbnb'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }