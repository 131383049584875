var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"col-md-3 col-lg-2 d-md-block border-0 sidebar collapse",attrs:{"id":"sidebarMenu"}},[_c('div',{staticClass:"position-sticky pt-3"},[_c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.name == 'client_employee_search' || _vm.$route.name == 'client_employee_view'
                      ? 'router-link active'
                      : 'router-link',attrs:{"to":{ name: 'client_employee_search', params: { id: _vm.client_id } },"aria-current":"page"}},[_c('span',{attrs:{"data-feather":"shopping-cart"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"17.28","height":"18","viewBox":"0 0 17.28 18"}},[_c('path',{attrs:{"id":"Path_118","data-name":"Path 118","d":"M0,0H17.28V17.28H0Z","transform":"translate(0 0.72)","fill":"none"}}),_c('path',{attrs:{"id":"Path_119","data-name":"Path 119","d":"M4,19a6.857,6.857,0,1,1,13.714,0Zm7.714-5.072v3.357h3.993A5.151,5.151,0,0,0,11.714,13.928ZM10,17.286V13.928a5.151,5.151,0,0,0-3.993,3.357Zm.857-6A5.143,5.143,0,1,1,16,6.143,5.141,5.141,0,0,1,10.857,11.286Zm0-1.714A3.429,3.429,0,1,0,7.429,6.143,3.428,3.428,0,0,0,10.857,9.571Z","transform":"translate(-2.217 -1)"}})])]),_vm._v(" Employees ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.name == 'client_reports'
                      ? 'router-link active'
                      : 'router-link',attrs:{"to":{ name: 'client_reports', params: { id: _vm.client_id } },"aria-current":"page"}},[_c('span',{attrs:{"data-feather":"home"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","data-name":"report-svgrepo-com (1)","width":"14.446","height":"18.168","viewBox":"0 0 14.446 18.168"}},[_c('path',{attrs:{"data-name":"Path 9441","d":"M29.45 0H19.7v18.168h14.445V4.695zm2.275 4.28h-1.86V2.42zM21.116 16.751V1.417h7.332v3.572a.709.709 0 0 0 .709.709h3.571v11.053z","transform":"translate(-19.699)"}}),_c('path',{attrs:{"data-name":"Rectangle 2342","transform":"translate(3.16 14.078)","d":"M0 0h8.126v.945H0z"}}),_c('path',{attrs:{"data-name":"Rectangle 2343","transform":"translate(3.16 5.206)","d":"M0 0h4.824v.945H0z"}}),_c('path',{attrs:{"data-name":"Path 9442","d":"M58.178 77.88h.522l-2.022 2.02-1.578-1.579-3.5 3.5.668.668 2.83-2.831 1.582 1.582 2.692-2.692v.522h.945v-2.135h-2.139z","transform":"translate(-48.583 -69.666)"}}),_c('path',{attrs:{"data-name":"Rectangle 2344","transform":"translate(3.16 3.145)","d":"M0 0h4.824v.945H0z"}})])]),_vm._v(" Reports ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.name == 'client_requests'
                      ? 'router-link active'
                      : 'router-link',attrs:{"to":{ name: 'client_requests', params: { id: _vm.client_id }  },"aria-current":"page"}},[_c('span',{attrs:{"data-feather":"home"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13.588","height":"16.56","viewBox":"0 0 13.588 16.56"}},[_c('path',{attrs:{"data-name":"Icon material-notifications-none","d":"M12.794 20.31a1.7 1.7 0 0 0 1.7-1.7H11.1a1.7 1.7 0 0 0 1.694 1.7zm5.1-5.1v-4.241c0-2.607-1.384-4.79-3.822-5.367v-.578a1.274 1.274 0 0 0-2.548 0V5.6C9.091 6.179 7.7 8.353 7.7 10.969v4.246L6 16.913v.849h13.588v-.849zm-1.7.849H9.4v-5.1c0-2.106 1.282-3.822 3.4-3.822s3.4 1.715 3.4 3.822z","transform":"translate(-6 -3.75)"}})])]),_vm._v(" Requests ")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.name == 'client_followups'
                      ? 'router-link active'
                      : 'router-link',attrs:{"to":{ name: 'client_followups', params: { id: _vm.client_id }  },"aria-current":"page"}},[_c('span',{attrs:{"data-feather":"home"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"19.408","height":"19.207","viewBox":"0 0 19.408 19.207"}},[_c('path',{staticStyle:{"stroke":"transparent","stroke-miterlimit":"10"},attrs:{"data-name":"Icon ionic-md-arrow-up","d":"M16.1 23.977V10.308l6.3 6.3 1.575-1.631-9-9-9 9 1.575 1.575 6.3-6.243v13.668z","transform":"translate(-5.269 -5.269)"}})])]),_vm._v(" Follow-Ups ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }