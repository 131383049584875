export const namespaced = true;
export const arrHelpCenter = {
    DashboardTutorials: [
        {
            question: "Learning the Basics",
            answer: '<p class="text_balticsea mb_30">Welcome to your dashboard tutorial library. Here you can get acquainted with your program management tools and learn the basics that govern the use of our dashboards!</p>',
            icon: require('@/assets/images/dashboard/lbi.png'),
            oldIcon: require('@/assets/images/dashboard/lbi.png'),
            newIcon: false,
            vimeoVideos : [{"linkTitle":"Meet Your Dashboards", "linkUrl":"https://player.vimeo.com/video/889541303?h=460709408b&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Learn the Basics", "linkUrl":"https://player.vimeo.com/video/889544847?h=356be050d8&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"}]
        },
        {
            question: "Ergo Activity Dashboards",
            answer: '<p class="text_balticsea mb_30">Your ergo activity dashboards are designed to give you full control over your ergonomics program.  You’ll easily be able to measure all ergo-activity within your organization including things like overall site engagement, ergo assessment status,real time cost savings and access to employee records. Let’s dive in!</p>',
            icon: require('@/assets/images/dashboard/pmi.png'),
            oldIcon: require('@/assets/images/dashboard/pmi.png'),
            newIcon: false,
            vimeoVideos : [{"linkTitle":"Program Overview", "linkUrl":"https://player.vimeo.com/video/889544572?h=4cc3a798aa&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Self-Assessment", "linkUrl":"https://player.vimeo.com/video/889544223?h=3d75d0762d&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Ergo Eval Activity", "linkUrl":"https://player.vimeo.com/video/889543902?h=3b2599b83e&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Follow Up Feedback", "linkUrl":"https://player.vimeo.com/video/889543569?h=c10aee85f6&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Employee Search", "linkUrl":"https://player.vimeo.com/video/889543188?h=41a8443e0b&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Custom Search", "linkUrl":"https://player.vimeo.com/video/889542901?h=dc8638fa3d&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"}]
        },
        {
            question: "Your Personal Dashboards",
            answer: '<p class="text_balticsea mb_30">In addition to the wealth of program management dashboards and tools on this platform, we also provide employee dashboard access to all staff, including yourself! Check out how to access your personal ergo reports and recommendations as well as next steps when more help is needed.</p>',
            icon: require('@/assets/images/dashboard/ypd.png'),
            oldIcon: require('@/assets/images/dashboard/ypd.png'),
            newIcon: false,
            vimeoVideos : [{"linkTitle":"My Reports", "linkUrl":"https://player.vimeo.com/video/889542606?h=fec4f67d0b&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Help Center", "linkUrl":"https://player.vimeo.com/video/889542315?h=f338d95f49&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"}]
        },
        {
            question: "Evaluation Dashboards",
            answer: '<p class="text_balticsea mb_30">Your Ergo Evaluator Dashboard contains easy to follow tools which will aid  you in conducting, reviewing, editing, and finalizing an ergo evaluation as well as seamlessly submitting ergo reports on your behalf!</p>',
            icon: require('@/assets/images/dashboard/ergo_dashboard.png'),
            oldIcon: require('@/assets/images/dashboard/ergo_dashboard.png'),
            newIcon: false,
            vimeoVideos : [{"linkTitle":"Your Evaluation Dashboard", "linkUrl":"https://player.vimeo.com/video/917249454?h=2cb16b3979&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Conducting an Evaluations", "linkUrl":"https://player.vimeo.com/video/917249653?h=d853c69011&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Your Ergo Eval Form", "linkUrl":"https://player.vimeo.com/video/917250328?h=61d4d457ff&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}]
        },
        {
            question: "Program Management Dashboards",
            answer: '<p class="text_balticsea mb_30">Your program management dashboards are designed to give you full control over your ergonomics program. You’ll easily be able to measure all ergo-activity within your organization including things like overall site engagement, ergo assessment status, real time cost savings and access to employee records. Let’s dive in!</p>',
            icon: require('@/assets/images/dashboard/epm.png'),
            oldIcon: require('@/assets/images/dashboard/epm.png'),
            newIcon: false,
            vimeoVideos : [{"linkTitle":"Employee Search Dashboard", "linkUrl":"https://player.vimeo.com/video/917251312?h=b476352b06&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Reports Dashboard", "linkUrl":"https://player.vimeo.com/video/917251803?h=eaaa731821&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Eval Request Dashboard", "linkUrl":"https://player.vimeo.com/video/917251983?h=342d6cfc05&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},{"linkTitle":"Follow Up Dashboard", "linkUrl":"https://player.vimeo.com/video/917252169?h=874f9ae134&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}]
        }
    ],
    GeneralErgo: [
        {
            question: "Welcome to the employee ergo portal.",
            answer: '<p class="text_balticsea mb_30">Congratulations! And welcome to the beginning of a better you! The ergo portal is a robust platform that offers a variety of resources at your fingertips designed to help you feel and work at your best. Some of the features include:</p><ul class="text_balticsea helpcenter"style="margin: 0;padding: 0 20px;line-height: 1.5;"><li> An Ergonomic Self-Assessment </li><li> Setup Guidelines For Wherever You Are Working </li><li> Productivity & Focus Tips </li><li> Everyday Stretches </li><li> Posture Perfect Exercises </li><li> Wellness Strategies</li></ul>',
            icon: require('@/assets/images/dashboard/w1.png'),
            oldIcon: require('@/assets/images/dashboard/w1.png'),
            newIcon: false,
        },
        {
            question: "How many times can I take the self-assessment?",
            answer: '<p class="text_balticsea mb_30"><strong>Great news!</strong> You are welcome to take the self-assessment as many times as you want or need to! It is designed to help you assess your work area and habits in a variety of environments.</p><p class="text_balticsea mb_10">Here are some ideas of when you should consider taking a self-assessment:</p><ul class="text_balticsea helpcenter"style="margin: 0;padding: 0 20px;line-height: 1.5;"><li> To make sure your work setup is working for you.    (Wherever that is these days!) </li><li> If there are any changes to your work environment or    comfort levels. </li><li> As a periodic refresher to keep you on your game!</li></ul><div class="text-center w-100 mt_20"><a class="btn btn_primary" href="self_assessment_signup">    Take a Self-Assessment</a></div>',
            icon: require('@/assets/images/dashboard/help_center_img_1.png'),
            oldIcon: require('@/assets/images/dashboard/help_center_img_1.png'),
            newIcon: false,
        },
        {
            question: "Is there a limit on how many times I can visit the site?",
            answer: '<p class="text_balticsea mb_30"><strong>Nope!</strong> This is your ergo portal that is available to you 24/7 as long as you are an active employee at your current organization. </p><p class="text_balticsea">In fact, we encourage you to come to visit the site on a regular basis and take advantage of all of the great resources available to you!</p>',
            icon: require('@/assets/images/dashboard/w3.png'),
            oldIcon: require('@/assets/images/dashboard/w3.png'),
            newIcon: false,
        },
    ],
    MyReports: [
        {
            question: "When will my ergo reports be available?",
            answer: '<p class="text_balticsea mb_30"><strong>For Self-Assessments,</strong> you will receive your report within a few minutes of completing your assessment. Once you hit submit, the system will send you an email letting you know that your report is waiting for your review in the portal profile found on the ergo site.</p> <p class="text_balticsea"><strong>For 1:1 Ergonomic Evaluations,</strong> you will receive your report within 2 - 5 business days. This allows your ergonomist to look everything over and review your report before sending it to you. You will receive an email when it is ready for you to view in your portal profile, found on the ergo site.</p>',
            icon: require('@/assets/images/dashboard/w7.png'),
            oldIcon: require('@/assets/images/dashboard/w7.png'),
            newIcon: false,
        },
        {
            question: "How do I view my ergo report?",
            answer: '<p class="text_balticsea mb_30"><strong>It’s easy!</strong> After completing your self-assessment or your 1:1 Ergonomic Evaluation with an ergonomist, you will receive a summary report that contains your findings and recommendations. </p> <p class="text_balticsea">Your summary reports can be found in your portal profile found at the top right corner of the ergo site. </p> <img src="../../assets/images/dashboard/help_center.png" alt="" class="w-75"> <p class="text_balticsea">You will find the full history of all summary reports in your portal profile. </p>',
            icon: require('@/assets/images/dashboard/w8.png'),
            oldIcon: require('@/assets/images/dashboard/w8.png'),
            newIcon: false,
        },
        {
            question: "How do I download my ergo report?",
            answer: '<p class="text_balticsea mb_0"><strong> All of your ergo summary reports </strong> are automatically saved in your portal profile. If you want to download your reports, you can do so by clicking on the download icon and your report can be saved on your computer as a PDF file. </p>',
            icon: require('@/assets/images/dashboard/w9.png'),
            oldIcon: require('@/assets/images/dashboard/w9.png'),
            newIcon: false,
        },
    ],
    MyData: [
        {
            question: "Is my data secure?",
            answer: '<p class="text_balticsea mb_30"><strong>Yes,</strong> all data is stored and transmitted in compliance with HIPAA, GDPR and CPRA regulations, including standard levels of encryption. </p> <p class="text_balticsea">For more information please see the <a href="/privacy-policy"><strong style="color:#39353D;">Privacy Policy.</strong></a> </p>',
            icon: require('@/assets/images/dashboard/w4.png'),
            oldIcon: require('@/assets/images/dashboard/w4.png'),
            newIcon: false,
        },
        {
            question: "Who sees my data?",
            answer: '<p class="text_balticsea mb_30">Your information may be shared with designated members of your organization for internal use only. We will never sell or share your information with any outside organization. </p> <p class="text_balticsea">For more information please see the <a href="/privacy-policy"><strong style="color:#39353D;">Privacy Policy.</strong></a> </p>',
            icon: require('@/assets/images/dashboard/w5.png'),
            oldIcon: require('@/assets/images/dashboard/w5.png'),
            newIcon: false,
        },
        {
            question: "How do I opt out? (GDPR, CPRA, etc.)",
            answer: '<p class="text_balticsea mb_30">Should you want to exercise your right to alter or remove your identifying information, please contact us at <a href="mailto:support@pbergo.com"><strong style="color:#39353D;">support@pbergo.com </strong></a> </p> <p class="text_balticsea">For more information please see the <a href="/privacy-policy"><strong style="color:#39353D;">Privacy Policy.</strong></a></p>',
            icon: require('@/assets/images/dashboard/w6.png'),
            oldIcon: require('@/assets/images/dashboard/w6.png'),
            newIcon: false,
        },
    ],
    AdditionalSupport: [
        {
            question: "What if I need further help?",
            answer: '<p class="text_balticsea mb_0"><strong>If you need further support,</strong> please refer to your ergo summary report found in your portal profile. Your report will let you know how to request further support within your organization. </p>',
            icon: require('@/assets/images/dashboard/w10.png'),
            oldIcon: require('@/assets/images/dashboard/w10.png'),
            newIcon: false,
        },
        {
            question: "How do I request an ergo eval?",
            answer: '<p class="text_balticsea mb_0"><strong>If you are in need of an ergonomic evaluation,</strong> please refer to your ergo summary report found in your portal profile. Your report will let you know how to request further support within your organization. </p>',
            icon: require('@/assets/images/dashboard/w11.png'),
            oldIcon: require('@/assets/images/dashboard/w11.png'),
            newIcon: false,
        },
        {
            question: "If equipment is recommended, what do I do next?",
            answer: '<p class="text_balticsea mb_0"><strong>If the equipment was recommended </strong> as a result of your self-assessment or 1:1 ergonomic evaluation, please refer to your ergo summary report found in your portal profile. Your report will list any equipment recommendations as well as for instructions on what to do next.</p>',
            icon: require('@/assets/images/dashboard/w12.png'),
            oldIcon: require('@/assets/images/dashboard/w12.png'),
            newIcon: false,
        },
    ]
};

export const arrWorkAreaDetails = {
    Office: {
        oldIcon: require("@/assets/images/homepage/office.png"),
        icon: require("@/assets/images/homepage/office.png"),
        title: "Working from office?",
        description: "Discover your ideal setup, learn the best ergo practices and be on your A game every single day.",
    },
    Home: {
        oldIcon: require("@/assets/images/homepage/home.png"),
        icon: require("@/assets/images/homepage/home.png"),
        title: "Working from home?",
        description: "Discover the best ways to bring ergo into your home, boost your productivity and feel great!",
    },
    Anywhere: {
        oldIcon: require("@/assets/images/homepage/anywhere.png"),
        icon: require("@/assets/images/homepage/anywhere.png"),
        title: "Working from anywhere?",
        description: "Hello Hybrid.  Shuttling back and forth between the office and home? We’ve got you.",
    },
    Lab: {
        oldIcon: require("@/assets/images/homepage/lab.png"),
        icon: require("@/assets/images/homepage/lab.png"),
        title: "Working from lab?",
        description: "Learn how to navigate the variety of working environments inherent in labs to feel great.",
    },
    Travel: {
        oldIcon: require("@/assets/images/homepage/travel-for-work-home.png"),
        icon: require("@/assets/images/homepage/travel-for-work-home.png"),
        title: "Do you travel for work?",
        description: "Stay on your A-game with good ergo and healthy work practices no matter where you are.",
    },
};

var baseUrl = window.location.origin;
//var baseUrl = process.env.BASE_URL;
export const arrSummaryReportInfo = {
    office_equipment_heading: '<h2 style="text-align: center;color: var(--default_color);margin-bottom: 25px;">For information on how to procure the recommended equipment below, please see our <a href="' + baseUrl + '" target="_blank">Guide </a>article.</h2>',
    home_equipment_heading: '<h2 style="text-align: center;color: var(--default_color);margin-bottom: 25px;">For information on how to procure the recommended equipment below, please see our <a href="' + baseUrl + '" target="_blank">Guide </a>article.</h2>',
    lab_equipment_heading: '<h2 style="text-align: center;color: var(--default_color);margin-bottom: 25px;">For information on how to procure the recommended equipment below, please see our <a href="' + baseUrl + '" target="_blank">Guide </a>article.</h2>',
    wfh_saftey_message: '<p style="text-align: center">If you have any questions about your home setup or have safety concerns, please reach out to your manager for support.</p><p></p>',
    summary_report_footer: '<h2 style="text-align: center;">For further help, please reach out to:</h2><h2 style="text-align: center;"><a href="mailto:support@pbergo.com">support@pbergo.com</a></h2>',
    conditional_msg_content:`<strong>Do you need more help?</strong><p>If you need further help after implementing the recommendations in the report and would like to have a consultation with an ergonomist, you can click <a href='${baseUrl}/user/schedule_eval' target='_blank'>HERE</a> to schedule a virtual ergonomic evaluation.</p><br />`
};
export const strScheduleRequest = "<p>Do you need a bit more help after taking your self-assessment? We've got you covered. Schedule a one-on-one ergo evaluation with one of our leading ergonomists.</p>";
export const getters = {
    helpCenter: () => {
        return arrHelpCenter;
    },
    workAreaDetails: () => {
        return arrWorkAreaDetails;
    },
    summaryReportInfo: () => {
        return arrSummaryReportInfo;
    },
    scheduleRequest: () => {
        return strScheduleRequest;
    },
};