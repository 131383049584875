export default function common({ to, next, store }) {

    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
    const storageItem = window.localStorage.getItem("guest");
    if (storageItem === "isNotGuest" && !store.getters["auth/authUser"]) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (store.getters["auth/authUser"]) {
                var exists = store.getters["auth/userRoles"].includes('Super_admin');
                if (exists) {
                   // next({ name: "SuperadminDashboard" });
                   next();
                }
                else {
                    next({ name: "dashboard" });
                }
            } else {
                window.localStorage.removeItem('userToken');
                store.dispatch("auth/setGuest", { value: "isGuest" });
                next();
            }
        });
    } else if (!store.getters["auth/authUser"]) {
        next(loginQuery);
    } else {
        next();
    }
}