<template>
  <!-- header start -->
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
          <img :src="company_logo" alt="img" class="img-fluid" />
        </router-link>
        <div class="common-link dropdown d-block d-lg-none ms-auto user mr_20">
          <a class="dropdown-toggle user-profile no-underline" href="#" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" @click="changeVal()">
            <img :src="
              avatar
                ? avatar
                : '../assets/images/home-essentials/user-image.png'
            " alt="icon" class="img-fluid" />
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <router-link :to="{ name: 'profile' }" class="dropdown-item"
                @click="manageTogglerCross">Profile</router-link>
            </li>
            <li  v-show="adminpermission">
              <router-link :to="{ name: 'admin_center' }" class="dropdown-item" @click="manageTogglerCross">Admin Dashboard</router-link>
            </li>
            <li v-show="employeepermission">
              <router-link :to="{ name: employeeroute }" class="dropdown-item">{{ employeeTitle }}</router-link>
            </li>
            <li @click="checkDomain" v-if="switchClient">
              <a class="dropdown-item">Switch Client</a>
            </li>
            <li @click="manageTogglerCross">
              <a @click="logout" class="dropdown-item">Logout</a>
            </li>
          </ul>
        </div>

        <button id="manageTogglerCross" type="button" class="navbar-toggler minus collapsed" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" :aria-expanded="false"
          aria-label="Toggle navigation" v-on:click="checkMenu">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div @click="showHideToggler = !showHideToggler" class="collapse navbar-collapse align-self-center"
          id="navbarSupportedContent" v-scroll-lock="open">
          <ul class="navbar-nav ms-lg-auto mb-2 mb-lg-0 align-items-lg-center">
            <li class="nav-item" @click="manageTogglerCross"
              v-show="ergopermission && site_menu_details.Home.status == 1">
              <!-- <router-link :to="{ name : 'index' }" class="nav-link active" aria-current="page" >Home</router-link> -->
              <router-link :to="{ name: 'user_home' }" class="nav-link" active-class="active" exact>{{
                  site_menu_details.Home.custom_menu_text
              }}</router-link>
            </li>
            <li class="nav-item" @click="manageTogglerCross"
              v-show="ergopermission && site_menu_details.ErgoEssentials.status == 1">
              <router-link :to="{ name: 'ergo_essentials' }" class="nav-link" active-class="active" exact>{{
                  site_menu_details.ErgoEssentials.custom_menu_text
              }}</router-link>
            </li>
            <li class="nav-item" @click="manageTogglerCross"
              v-show="ergopermission && site_menu_details.SetupGuidelines.status == 1">
              <router-link :to="{ name: 'setup_guidline' }" class="nav-link" active-class="active" exact>{{
                  site_menu_details.SetupGuidelines.custom_menu_text
              }}</router-link>
            </li>
            <li class="nav-item" @click="manageTogglerCross"
              v-show="ergopermission && site_menu_details.WorkBetter.status == 1">
              <router-link :to="{ name: 'work_better' }" class="nav-link" active-class="active" exact>{{
                  site_menu_details.WorkBetter.custom_menu_text
              }}
              </router-link>
            </li>
            <li v-show="videopermission && site_menu_details.Videos.status == 1" class="nav-item"
              @click="manageTogglerCross">
              <router-link :to="{ name: 'ergo_video_library' }" class="nav-link" active-class="active" exact>{{
                  site_menu_details.Videos.custom_menu_text
              }}
              </router-link>
            </li>

            <li class="nav-item text-center" @click="manageTogglerCross" v-show="ergopermission && company_code">
              <router-link :to="{ name: 'self_assessment_signup' }" class="btn btn_primary">Take a Self-Assessment
              </router-link>
            </li>
            <li class="nav-item dropdown d-none d-lg-inline-block user">
              <a class="dropdown-toggle user-profile no-underline" href="#" id="navbarDropdownMenuLink" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img :src="
                  avatar
                    ? avatar
                    : '../assets/images/home-essentials/user-image.png'
                " alt="icon" class="img-fluid" />
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <router-link :to="{ name: 'profile' }" class="dropdown-item">Profile</router-link>
                </li>

                <li v-show="adminpermission">
                  <router-link :to="{ name: 'admin_center' }" class="dropdown-item">Admin Dashboard</router-link>
                </li>
                <li v-show="employeepermission">
                  <router-link :to="{ name: employeeroute }" class="dropdown-item">{{ employeeTitle }}</router-link>
                </li>
                <li @click="checkDomain" v-if="switchClient">
                  <a class="dropdown-item">Switch Client</a>
                </li>
                <!-- <li v-show="program_overview_permission"><router-link :to="{name: 'program_overview'}" class="dropdown-item">Program Overview</router-link></li>
                <li v-show="self_assessment_permission"><router-link :to="{name: 'self_assessment_activity'}" class="dropdown-item">Self-Assessment</router-link></li>
                <li v-show="ergo_eval_activity_permission"><router-link :to="{name: 'ergo_eval_activity'}" class="dropdown-item">Ergo Eval Activity</router-link></li>
                <li v-show="follow_up_feedback_permission"><router-link :to="{name: 'follow_up_feedback'}" class="dropdown-item">Follow-Up Feedback</router-link></li>
                <li v-show="employee_search_permission"><router-link :to="{name: 'employee_search'}" class="dropdown-item">Employee Search</router-link></li>
                <li v-show="client_activity_permission"><router-link :to="{name: 'client_activity'}" class="dropdown-item">Client Activity</router-link></li>
                <li v-show="evaluator_permission"><router-link :to="{name: 'evaluator'}" class="dropdown-item">Evaluator</router-link></li>
                <li v-show="client_manager_permission"><router-link :to="{name: 'client_manager'}" class="dropdown-item">Pbe Management</router-link></li> -->
                <li><a @click="logout" class="dropdown-item">Logout</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- <AutoLogout v-if="authUser"/> -->
    <clientPopup v-show="isModalVisible" @close="removeUser" :all-clients="this.clientsData"
      :sso-status="this.sso_status" @closeModal="isModalVisible = $event" />
    <!-- Region popup if not exist in user -->
    <regionPopup v-show="isRegionModalVisible" :all-regions="this.regionsData" @closeModal="isRegionModalVisible = $event" />
  </header>

  <!-- header end -->
</template>

<script>
// import AutoLogout from "../components/AutoLogout.vue"
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import clientPopup from "../components/Clientspopup.vue";
import regionPopup from "../components/Regionpopup.vue";
import commonFunction from '../mixin/commonFunction';
//import store from "@/store/index";
export default {
  name: "Header",
  mixins: [commonFunction],
  components: {
    clientPopup,
    regionPopup,
  },
  data() {
    return {
      company_logo: require("@/assets/images/logo.png"),
      showHideToggler: true,
      divscrolltop: 0,
      open: false,
      avatar: null,
      adminpermission: false,
      employeepermission: false,
      videopermission: false,
      ergopermission: false,
      employeeroute: "my_reports",
      employeeTitle: "Employee Dashboard",
      program_overview_permission: false,
      self_assessment_permission: false,
      ergo_eval_activity_permission: false,
      follow_up_feedback_permission: false,
      employee_search_permission: false,
      evaluator_permission: false,
      client_activity_permission: false,
      client_manager_permission: false,
      switchClient: false,
      userAuthToken: localStorage.getItem("userToken"),
      clientsData: [],
      regionsData:[],
      sso_status: false,
      isModalVisible: false,
      isRegionModalVisible: false,
      site_menu_details: {
        Home: {
          menu: 'Home',
          custom_menu_text: 'Home',
          status: 1,
        },
        ErgoEssentials: {
          menu: 'Ergo Essentials',
          custom_menu_text: 'Ergo Essentials',
          status: 1,
        },
        SetupGuidelines: {
          menu: 'Setup Guidelines',
          custom_menu_text: 'Setup Guidelines',
          status: 1,
        },
        WorkBetter: {
          menu: 'Work Better',
          custom_menu_text: 'Work Better',
          status: 1,
        },
        Videos: {
          menu: 'Videos',
          custom_menu_text: 'Videos',
          status: 1,
        },
      },
      company_code: true,
    };
  },

  beforeMount() {
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      let res = this.$store.getters["auth/authUser"];
      if (res) {
        let allPermission = res.user.roles.all_permission;
        if(allPermission.includes("switch-client")){
          if (res.user.admin_sso_status == 0) {
          this.switchClient = true;
          } else {
            this.switchClient = false;
          }
        }
        this.ergopermission = allPermission.includes(
          "site-ergo-ally-site-view"
        );
        this.adminpermission = allPermission.includes(
          "site-access-admin-center-view"
        );
        this.videopermission = allPermission.includes(
          "site-video-page-only-view"
        );

        this.program_overview_permission = allPermission.includes(
          "dashboard-pages-program-overview-view"
        );
        this.self_assessment_permission = allPermission.includes(
          "dashboard-pages-self-assessment-view"
        );
        this.ergo_eval_activity_permission = allPermission.includes(
          "dashboard-pages-ergo-eval-activity-view"
        );
        this.follow_up_feedback_permission = allPermission.includes(
          "dashboard-pages-follow-up-feedback-view"
        );
        this.employee_search_permission = allPermission.includes(
          "dashboard-pages-employee-search-view"
        );
        // this.evaluator_permission = allPermission.includes('dashboard-pages-evaluator');

        let evaluatorSubPermissions = [
          "evaluator-client-list",
          "evaluator-start-new-evaluation",
          "evaluator-reports-in-review",
        ];
        this.evaluator_permission = allPermission.some((item) =>
          evaluatorSubPermissions.includes(item)
        );

        this.client_activity_permission = allPermission.includes(
          "dashboard-pages-client-activity-view"
        );
        this.client_manager_permission = allPermission.includes(
          "dashboard-pages-client-managers-view"
        );

        let empPermissionArray = [
          "dashboard-pages-employee-dashboard-view",
          "dashboard-pages-employee-help-center-view",
          "dashboard-pages-program-overview-view",
          "dashboard-pages-self-assessment-view",
          "dashboard-pages-ergo-eval-activity-view",
          "dashboard-pages-follow-up-feedback-view",
          "dashboard-pages-employee-search-view",
          "evaluator-client-list",
          "evaluator-reports-in-review",
          "dashboard-pages-client-activity-view",
          "dashboard-pages-client-managers-view",
        ];
        let result = empPermissionArray.some((i) => allPermission.includes(i));
        if (result) {
          this.employeepermission = true;
          if (
            allPermission.includes("dashboard-pages-employee-dashboard-view")
          ) {
            this.employeeroute = "my_reports";
            this.employeeTitle = "Employee Dashboard";
          } else if (this.program_overview_permission) {
            this.employeeroute = "program_overview";
            this.employeeTitle = "Program Overview";
          } else if (this.self_assessment_permission) {
            this.employeeroute = "self_assessment_activity";
            this.employeeTitle = "Self-Assessment";
          } else if (this.ergo_eval_activity_permission) {
            this.employeeroute = "ergo_eval_activity";
            this.employeeTitle = "Ergo Eval Activity";
          } else if (this.follow_up_feedback_permission) {
            this.employeeroute = "follow_up_feedback";
            this.employeeTitle = "Follow-Up Feedback";
          } else if (this.employee_search_permission) {
            this.employeeroute = "employee_search";
            this.employeeTitle = "Employee Search";
          } else if (this.evaluator_permission) {
            this.employeeroute = "evaluator";
            this.employeeTitle = "Evaluator";
          } else if (this.client_activity_permission) {
            this.employeeroute = "client_activity";
            this.employeeTitle = "Client Activity";
          } else if (this.client_manager_permission) {
            this.employeeroute = "client_manager";
            this.employeeTitle = "Program Management";
          } else {
            this.employeeroute = "help_center";
            this.employeeTitle = "Employee Help Center";
          }
        }
      }
    } else {
      // this.logout();
    }
  },
  mounted() {
    this.base_url = process.env.VUE_APP_API_URL;
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let data = this.$store.getters["auth/authUser"];
    
    if(data.user.region=='' || data.user.region== null){
      
      this.isRegionModalVisible=true;
      var body = document.body;
      body.classList.add("hide_scroll");
      var arrRegions = JSON.parse(data.client.client_regions);
      // var arrRegions = decodedData["regions"];
      for (let x in arrRegions) {
      this.regionsData .push({
        id: arrRegions[x], name: arrRegions[x]
        });
      }
    }
    
    let company_data = data.client;
    /** Hide Take a self assessment button from the Twillo */
  
    if( data.client.self_assessment_button_status == 0){
      this.company_code = false;
    }
    
    this.sso_status = data.user.sso_status;
    if (company_data == null) {
      company_data = [];
    }
    if (company_data.length == 0 || company_data.length === "undefined") {
      console.log("no company values header");
    } else {
      this.company_logo = company_data.logo;
      if (company_data.site_menu_details != undefined) {
        if (company_data.site_menu_details.Home != undefined) {
          this.site_menu_details.Home = company_data.site_menu_details.Home;
        }
        if (company_data.site_menu_details.WorkBetter != undefined) {
          this.site_menu_details.WorkBetter = company_data.site_menu_details.WorkBetter;
        }
        if (company_data.site_menu_details.SetupGuidelines != undefined) {
          this.site_menu_details.SetupGuidelines = company_data.site_menu_details.SetupGuidelines;
        }
        if (company_data.site_menu_details.Videos != undefined) {
          this.site_menu_details.Videos = company_data.site_menu_details.Videos;
        }
        if (company_data.site_menu_details.ErgoEssentials != undefined) {
          this.site_menu_details.ErgoEssentials = company_data.site_menu_details.ErgoEssentials;
        }
      }
    }
    this.avatar = localStorage.getItem("user_profile");
  },
  methods: {
    checkDomain() {
      if (localStorage.getItem("userToken")) {
        AuthService.userDomainCheck()
          .then((response) => {
            var decodedJson = this.decodeAPIResponse(response.data.data);
            this.clientsData = decodedJson.clients;
            this.isModalVisible = true;
            const body = document.querySelector("body");
            body.style.overflow = "hidden";
          }).catch(({response} ) => {
            this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    removeUser() {
      if (localStorage.getItem("userToken")) {
        AuthService.removeUser()
          .then((response) => {
            if (response.data.delete == 0) {
              this.isModalVisible = false;
              const body = document.querySelector("body");
              body.style.removeProperty("overflow");
            }
          }).catch(({ response }) => {
            this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    logoutBackupR() {
      this.$store.dispatch("auth/logout");
    },
    manageTogglerCross() {
      document.getElementById("manageTogglerCross").classList.add("collapsed");
      if (
        document
          .getElementById("manageTogglerCross")
          .getAttribute("aria-expanded") == "true"
      ) {
        document
          .getElementById("manageTogglerCross")
          .setAttribute("aria-expanded", false);
        document
          .getElementById("navbarSupportedContent")
          .classList.remove("show");
        document.querySelector("body").style.overflow = "auto";
      }
    },
    logout() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      AuthService.logout()
        .then(() => {
          var LoginType = window.localStorage.getItem("loginType");
          window.localStorage.removeItem("user_profile");
          window.localStorage.removeItem("id_token_client");
          window.localStorage.removeItem("userToken");
          window.localStorage.removeItem("companyInformation");
          window.localStorage.removeItem("loginType");
          window.localStorage.removeItem("employeeEmail");
          window.localStorage.clear();
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if(LoginType == 1) {
            this.$router.push({
              name: "logout",
            });
            // this.$toast.success('You have been logged out.  You can close this window.', {
            //   position: "top-right",
            //   duration: 15000,
            // });
          }else{
            this.$router.push({
              name: "login",
            });
          }
          
        })
        .catch((error) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          this.error = getError(error);
          const errType = Object.prototype.toString
            .call(getError(error))
            .slice(8, -1)
            .toLowerCase();
          if (errType === "string") {
            this.checkType = true;
          }
        });
    },
    scrollvue() {
      let navbar = document.querySelector(".navbar");
      this.divscrolltop = window.scrollY;
      if (this.divscrolltop > "50") {
        navbar.classList.add("scroll-header");
      } else {
        navbar.classList.remove("scroll-header");
      }
    },
    checkMenu: function (event) {
      if (event.currentTarget.getAttribute("aria-expanded") == "true") {
        this.open = true;
        document.querySelector("body").classList.remove("overflow-auto");
      } else {
        this.open = false;
      }
    },
    changeVal() {
      var x = document
        .getElementById("manageTogglerCross")
        .getAttribute("aria-expanded");
      if (x == "false") {
        x = "true";
      } else {
        x = "false";
        document
          .getElementById("manageTogglerCross")
          .classList.add("collapsed");
        document
          .getElementById("navbarSupportedContent")
          .classList.remove("show");
        document.querySelector("body").classList.add("overflow-auto");
      }
      document
        .getElementById("manageTogglerCross")
        .setAttribute("aria-expanded", x);
    },
  },
};
</script>
