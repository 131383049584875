// import Homeservices from "../../services/Homeservices";

export const namespaced = true;

export const state = {
    data: [],
}

export const actions = {
    getHomeData() {
        // Homeservices.getData().then((response) => {
        //    return response;
        // }).catch((error) => {
        //     console.log(error);
        // })
    }
}

