<template>
  <!-- Footer Start Here -->
  <footer v-if="$route.meta.is_header != 'airbnb'" ref="headercompnent">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-xl-12 col-md-12">
          <div class="footer-section mt_30">
            <ul class="mb_30 ps-0">
              <li class="footer-links">BE MINDFUL</li>
              <li class="footer-links">BE PRESENT</li>
              <li class="footer-links">BE GREAT</li>
            </ul>
          </div>
          <div
            class="company-site-footer footer_list" v-if="$route.meta.is_header != 'admin' && $route.meta.is_header != 'login' "
            v-html="company_site_footer"
          ></div>
          <div class="footer-section mt_84 sml_margin">
            <ul class="ps-0">
              <li>
                <router-link  target="_blank"
                  :to="{ name: 'privacy_policy' }"
                  class="privacy-links"
                >
                  Privacy Policy
                </router-link>
              </li>
              <li class="privacy-links">/</li>
              <li>
                <router-link  target="_blank"
                  :to="{ name: 'terms_of_service' }"
                  class="privacy-links"
                >
                  Terms of Service
                </router-link>
              </li>
            </ul>
          </div>

          <div class="copy-right">
            <p class="mb_0">© {{currentYear()}} | Page Break Enterprises</p>
          </div>
          <div id="google_translate_element" v-show="$route.meta.is_header != 'admin'"></div>
        </div>
      </div>
    </div>
    <cookie-law theme="dark-lime" v-if="$route.meta.is_header != 'admin'">
      <div slot-scope="props">
        <h4>Your Privacy</h4>
        <p>We use cookies to improve your experience on our side.</p>
        <div slot="message">
          To find out more, read out our
          <router-link :to="{ name: 'privacy_policy' }" target="_blank">Privacy Policy</router-link> or our
          <router-link :to="{ name: 'terms_of_service' }" target="_blank">Terms Of Service</router-link>
        </div>
        <button class="btn btn_primary" @click="props.accept">Ok</button>
      </div>
    </cookie-law>
  </footer>
  <!-- Footer end Here -->
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "Footer",
  components: { CookieLaw },
  data() {
    return {
      company_site_footer: null,
    };
  },
  mounted() {
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let data = this.$store.getters["auth/authUser"];
    if(data){
      let company_data = data.client;
      
      if(company_data == null){
          company_data = [];
        }
      if(company_data.length == 0 || company_data.length === 'undefined'){ 
          console.log('no company values footer')
        }else{
          this.company_site_footer = company_data.site_footer;
        } 
    }
  },
   methods: {
    currentYear() {
      const current = new Date();
      const date = `${current.getFullYear()}`;
      return date;
    }
  }
};
</script>

