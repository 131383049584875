import AuthService from "@/services/AuthService";
export default {
    methods: {
        tokenExpired() {
            this.$toast.error("Session Expired. Please log in again.", {
                position: "top-right",
            });
            setTimeout(() => this.$router.push({ name: 'login' }), 500);
        },
        onLostFocusEditor(element) {
            setTimeout(() => {
                this.$validator.validate(element);
            }, 10);
        },
        generateRandomString(stringLength) {
            let result = "";
            const alphaNumericCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            const alphabetsLength = alphaNumericCharacters.length;
            for (let i = 0; i < stringLength; i++) {
                result += alphaNumericCharacters.charAt(Math.floor(Math.random() * alphabetsLength));
            }
            return result;
        },
        decodeAPIResponse(encrypted_data) {
            var key = process.env.VUE_APP_ENC_KEY;
            var break_data = encrypted_data.replace(key, '');
            var slice_random = break_data.slice(20);
            var decrypted_json = JSON.parse(window.atob(slice_random));
            return decrypted_json;
        },
        encodeAPIRequest(request_data) {
            return this.generateRandomString(10) + process.env.VUE_APP_ENC_KEY + this.generateRandomString(10) + btoa(JSON.stringify(request_data));
        },
        encodeAPIRequestWithImages(request_data) {
            return this.generateRandomString(10) + process.env.VUE_APP_ENC_KEY + this.generateRandomString(10) + btoa(unescape(encodeURIComponent(JSON.stringify(request_data))));
        },
        downloadPdfReport(url, dashboard, user_name) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;

            //Audit log for download
            var requestData = { 'report': "Reports in PDF", 'dashboard': dashboard, 'user_name': user_name };
            let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                },
                data: _this.encodeAPIRequestWithImages(requestData),
            };
            _this.axios(config)
                .then(({ data }) => {
                    console.log(data.message);
                });
            AuthService.downloadZipFile(url).then((response) => {
                var blobObj = new Blob([response.data], { type: "text/html" });
                var blobURL = window.URL.createObjectURL(blobObj);
                var iframe = document.createElement('iframe'); //load content in an iframe to print later
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobURL;
                iframe.onload = function () {
                    var dom = iframe.contentWindow.document;
                    dom.title = 'Summary-Report';
                    var iframeElements = dom.querySelectorAll('iframe');

                    iframeElements.forEach(el => {
                        if (el.classList.contains('skiptranslate') == false) {
                            var parentofIframe = el.parentNode;
                            var anchor = document.createElement('a');
                            anchor.setAttribute('href', el.src);

                            var thumbnailImg = document.createElement('img');
                            thumbnailImg.style.marginTop = '10px';
                            thumbnailImg.style.borderRadius = "20px";
                            thumbnailImg.style.border = '1px solid #000';
                            thumbnailImg.setAttribute('height', 140);
                            thumbnailImg.setAttribute('width', 250);

                            thumbnailImg.setAttribute('src', process.env.VUE_APP_API_URL + '/videothumbnail.jpeg');
                            anchor.appendChild(thumbnailImg);
                            parentofIframe.replaceChild(anchor, el);
                        }
                    });
                    var googleTranslator = dom.getElementById("google_translate_element");
                    if (googleTranslator != null || googleTranslator != undefined) {
                        googleTranslator.style.display = "none";
                    }

                    var backToTop = dom.getElementsByClassName('arrow');
                    if (backToTop.length > 0) {
                        Array.from(backToTop).forEach((btt) => {
                            btt.parentElement.style.display = "none";
                        });
                    }
                    setTimeout(function () {
                        iframe.focus();
                        iframe.contentWindow.print();
                        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                    }, 1000);
                };
            });
        },
        showPermissions(role_id) {
            var _this = this;
            _this.roleName = '';
            _this.grantedPermissions = [];
            if (role_id) {
                let config = {
                    method: "get",
                    url: process.env.VUE_APP_API_URL + "/api/get-role-permissions/" + role_id,
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                    },
                };
                _this
                    .axios(config)
                    .then(({ data }) => {
                        var decodedJson = _this.decodeAPIResponse(data.data);
                        // console.log(decodedJson);
                        this.roleName = decodedJson.records.display_name;
                        this.grantedPermissions = decodedJson.grantedPermissions;
                    })
                    .catch(({ response }) => {
                        if (response.status == 401) {
                            _this.$router.push({ name: "login" });
                        }
                        _this.$toast.error(response.data.error, {
                            position: "top-right",
                            duration: 5000,
                        });

                    });
            }
        },
        switchStatusColor(status) {
            let statusClass;
            switch (status) {
                case 'Re-Review':
                    statusClass = "blue_review";
                    break;
                case 'In Review':
                    statusClass = "gray_review";
                    break;
                case 'Hidden':
                    statusClass = "gray_review";
                    break;
                case 'Evaluator Reviewed':
                    statusClass = "orange_review";
                    break;
                case 'Evaluator QC Reviewed':
                    statusClass = "orange_review";
                    break;
                case 'Finalized':
                    statusClass = "light_blue_review";
                    break;
                case 'Declined':
                    statusClass = "pink_review";
                    break;
                case 'Pending Client Approval':
                    statusClass = "gold_review";
                    break;
                default:
                    statusClass = "";
            }
            return statusClass;
        },
        generateDatasets() {
            const currentYear = new Date().getFullYear();
            const years = [];

            // Generate the years from 2022 to the current year
            for (let year = 2021; year <= currentYear; year++) {
                years.push(year);
            }

            // Define border colors
            const borderColors = [
                'rgba(255, 99, 132, 0.6)', // Red
                'rgba(54, 162, 235, 0.6)', // Blue
                'rgba(255, 206, 86, 0.6)', // Yellow
                'rgba(75, 192, 192, 0.6)', // Green
                'rgba(153, 102, 255, 0.6)', // Purple
                'rgba(255, 159, 64, 0.6)', // Orange
                // Add more colors as needed
            ];

            // Create datasets for each year with different data
            const datasets = years.map((year, index) => ({
                label: year.toString(),
                data: this.generateRandomData(), // Call function to generate random data
                borderColor: borderColors[index % borderColors.length],
                backgroundColor: 'transparent',
            }));

            return datasets;
        },
        generateRandomData() {
            const data = [];
            for (let i = 0; i < 12; i++) { // Assuming 12 months in a year
                data.push(Math.floor(Math.random() * 50) + 1); // Generate random number between 1 and 50
            }
            return data;
        }

    },

}