<template>
  <transition name="fade">
    <div class="sso-modal">
    <div class="custom-modal-backdrop box_up user_box_up">
      <div role="dialog" :class="[regionRequired ? 'custom-modal pop_custom region_popup' : 'custom-modal pop_custom']">
        <header class="modal-header for_pos" id="modalTitle">
          <slot name="header">Welcome to PBErgo</slot>  
          <small
            class="error text-danger d-none"
            v-show="errors.has('updateSsoClientForm.Client')"
            >
              Client is required
            </small> 
            
        </header>
        <section class="modal-body add_scroller cust_scroll" id="modalDescription">
          <slot name="body" v-if="allClients.length">
            <form
              data-vv-scope="updateSsoClientForm"
            >
              <div class="form-body marg_none">
                <div class="grid" v-show="!regionRequired">
                  <div class="popup_card" v-for="(client, index) in allClients" :key="index" :for="'Client_'+index">
                    <input
                      type="radio"
                      name="Client"
                      :id="'Client_'+index"
                      aria-label="..."
                      class="form-check-input ms-0 radio"
                      :value="client.id"
                      v-model="client_id"
                      v-validate="'required'"
                      checked=""
                      @click="selectRegion(client.id)"
                    />
                    <!-- Need to add public inside the image path for calling on staging -->
                    <span class="plan-details">
                    <img
                      v-show="client.id && !client.logo.includes('base64')"
                      :src="
                      client.logo
                      ? base_url + '/client/images/' + client.logo
                      : require('@/assets/admin/images/logo.png')
                      "
                      style="width: 60px; margin-left: 16px"
                    />
                    <!-- <img
                      :src="require('@/assets/admin/images/logo.png')"
                      style="width: 100px;"
                    /> -->
                    {{ client.company_name }}
                  </span>
                  </div>
                </div>
                <div class="grid regionin" v-show="regionRequired">
                    <v-select
                      :reduce="(region_list) => region_list.id"
                      :options="region_list"
                      label="name"
                      placeholder="Region"
                      name="Region"
                      v-model="region"
                      v-validate="ssoStatus ? 'required' : '' "
                    />
                </div>
                <small
            class="error text-danger"
            v-show="regionRequired && errors.has('updateSsoClientForm.Region')"
            >
              Region is required
            </small>       
              </div>
              
            </form>
          </slot>
          <slot name="body" v-else>
            <p class="d-flex justify-content-center align-items-center no_client_pop">Client is not available.</p>
            <!-- <button
              type="button"
              class="btn btn_outline ml_24"
              @click="$emit('close')"
            >
              Cancel
            </button> -->
          </slot>
        </section>
        <div class="btnsgroup">
          <button type="button" v-if="ssoStatus && regionRequired" class="btn btn_primary pop_btn" @click="regionRequired=false;region='';$validator.reset();">Change Client</button>
          <button type="button" v-if="allClients.length && ((ssoStatus && regionRequired) || !ssoStatus)" @click="updateSsoClient" class="btn btn_primary pop_btn ml_24">{{ ssoStatus ? 'Login' : 'Update' }}</button>
          <button type="button" class="btn btn_outline ml_24 pop_btn blind_bg" @click="$emit('close'),client_id='',$validator.reset();">
          Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  </transition>
</template>
<script>
import commonFunction from '../mixin/commonFunction';
import "vue-select/dist/vue-select.css";
export default {
  name: "clientspopup",
  mixins: [commonFunction],
  data() {
    return {
      client_id: "",
      region: "",
      base_url: null,
      userData: null,
      // isValidForm: false,
      regionRequired: false,
      region_list: [],
    };
  },
  props: {
    allClients: {
      type: [Array, Object],
    },
    ssoStatus: {
      type: [Boolean, Number],
    }
  },
  mounted() {
    this.base_url = process.env.VUE_APP_API_URL;
    let userData = this.$store.getters["auth/authUser"];
    this.userData = userData;
    
  },
  methods: {
    updateSsoClient() {
      var _this = this;
      var requestData = { client_id: _this.client_id, region:_this.region};
      _this.$validator.validateAll("updateSsoClientForm").then((isValid) => {
        if (isValid) {
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/update-sso-clients/",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequest(requestData)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = this.decodeAPIResponse(data.data);
                window.localStorage.removeItem("companyInformation");
                window.localStorage.setItem("companyInformation", JSON.stringify(decodedJson.companyData));
                this.$emit("closeModal", false);
                _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 2000,
                  });
                  setTimeout(() => {
                    if(decodedJson.companyCode.toLowerCase() == 'airbnb' || decodedJson.companyCode.toLowerCase() == 'a45')
                      window.location.href = window.location.origin+'/airbnb';
                    else
                      window.location.href = window.location.origin+'/user';
                      
                  },2000);
                
                // this.$router.push({
                //   name: "user_home",
                // });
              }
            })
            .catch((response) => {
              console.log(response);
              this.$emit("closeModal", false);
              _this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
              if (response.status == 401) {
                this.$router.push({ name: "login" });
              }
          });
        }
      });
    },
    selectRegion(clientId){
      if(this.ssoStatus){
        // console.log('in 1');
        this.regionRequired = true;
        var _this = this;
        let config = {
          method: "get",
          url:
            process.env.VUE_APP_API_URL +
            "/api/get-client-region/" +
            clientId,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          // data: _this.user_data,
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedData = _this.decodeAPIResponse(data.data);
            _this.region_list = [];
            // var arrRegions = JSON.parse(data["regions"]);
            var arrRegions = decodedData["regions"];
            for (let x in arrRegions) {
            _this.region_list .push({
              id: arrRegions[x], name: arrRegions[x]
              });
            }
          })
          .catch(({ response }) => {
            _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
            });
          });
      } else {
        console.log('else');
      }

    }
  },
};
</script>
<style>

.sso-modal{
  transition: none !important;
}
.sso-modal.fade-leave-active{
  transition: none !important;
}
.sso-modal.fade-leave-active.fade-leave-to{
  transition: none !important;
}
.sso-modal .custom-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #141414; */
  background: rgba(0,0,0,.8196078431);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: none;
}

.sso-modal .custom-modal {
  background: #fff;
  width: 70%;
  height: 50%;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  left: 15%;
  top: 20%;
  transition: none;
}

.sso-modal .modal-header {
  padding: 15px;
  display: flex;
}

.sso-modal .modal-header {
  border-bottom: 1px solid #fff;
  font-size: 30px;
  color: #000;
  justify-content: center;
}
.sso-modal .modal-body {
  position: relative;
  font-size: 30px;
  align-self: center;
  padding: 20px 10px;
}
.sso-modal .close {
  border: none;
  font-size: 30px;
  margin-left: 100px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  background: transparent;
}
.sso-modal .form-body {
  margin: 5%;
}
.admin_dash_cls .sso-modal .pop_custom .add_scroller .no_client_pop{
        color: #f19836 !important;
        border: 2px solid #f19836;
          font-size:26px;
          min-height: calc(50vh - 325px);
          margin: 100px 0px;
        
}
  @media screen and (max-width:991px) {
    .admin_dash_cls .sso-modal .pop_custom .add_scroller .no_client_pop{
            font-size: 20px;
            min-height: calc(52vh - 325px);       
            margin: 71px 0px;
          }
        }
 .admin_dash_cls .sso-modal .pop_custom .btnsgroup .blind_bg:hover {
    background-color: #f19836 !important;
    color: #fff !important;
    box-shadow: 0px 10px 17px -10px var(--color_orange);
}        
</style>
